import React from "react"
import { Script } from "gatsby"
export default function GlobalScripts() {
    return (
        <>
       
            <Script id="theme-config">
                {`
  var mytheme_urls = {
    theme_base_url: '/wp-content/themes/spalab/'
    , framework_base_url: '/wp-content/themes/spalab/framework/'
    , ajaxurl: ''
    , url: 'https://athenamedispa.com'
    , scroll: 'disable'
    , stickynav: 'disable'
    , loadingbar: 'enable'
    , is_admin: ''
    , skin: 'eggplant'
    , layout: 'wide'
    , layout_pattern: ''
};

  `}
            </Script>
            <Script id="layerslider-js-extra">
                {`
var LS_Meta = { "v": "5.6.9" };
  `}
            </Script>
            <Script id="pacemin-js-extra">
                {`
var paceOptions = { 'restartOnRequestAfter': '', 'restartOnPushState': '' };


  `}
            </Script>
        </>

// setTimeout(function(){
//     $("#loading").fadeOut(500);
// },1000)

    )
};