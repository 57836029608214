import React from "react"
// import { Link } from "gatsby"

import "../styles/emoji.css"
import "../wp-content/plugins/designthemes-core-features/shortcodes/css/animations.css"
import "../wp-content/plugins/designthemes-core-features/shortcodes/css/shortcodes.css"
//todo-vl check diff between two version of animations.css
import "../wp-content/plugins/designthemes-core-features/page-builder/css/animations.css"
import "../wp-includes/css/dist/block-library/style.min.css"
import "../wp-includes/css/dist/block-library/style.min.css"
import "../styles/wp-block-library-inline-css.css"
import "../wp-includes/js/mediaelement/mediaelementplayer-legacy.min.css"
import "../wp-includes/js/mediaelement/wp-mediaelement.min.css"
import "../styles/global-styles-inline-css.css"

import "../wp-content/plugins/LayerSlider/static/css/layerslider.css"
import "../wp-content/plugins/contact-form-7/includes/css/styles.css"
import "../wp-content/plugins/responsive-maps-plugin/includes/css/resmap.min.css"
import "../wp-content/themes/spalab/css/pace-theme-center-radar.css"

import "../wp-content/themes/spalab/css/reset.css";
import "../wp-content/themes/spalab/style.css"
import "../wp-content/themes/spalab/skins/eggplant/style.css"
import "../wp-content/themes/spalab/css/font-awesome.min.css"
import "../wp-content/themes/spalab/responsive.css"

// import Loader from "../components/loader"
import Footer from "../components/footer"
import BbBarWrapper from "../components/bbar-wrapper"
import Header from "../components/header"
import GlobalScripts from "../components/global-script"


export default function Layout({ children }) {
    return (
        <div>
            <GlobalScripts />
            {/* <Loader /> */}

            <div className="wrapper">
                <div className="inner-wrapper">
                   
                   
                    <BbBarWrapper />
                    <Header />

                    <div id="main">
                        <div className="container">
                            <section id="primary" className="content-full-width">
                                <main>{children}</main>

                            </section>
                        </div>
                    </div>

                    <Footer />

                </div>
            </div>
        </div>
    )
}
// const BodyAttributes = {
//     "data-theme": "dark"
// }
// exports.onRenderBody = ({
//     // setHeadComponents,
//     // setHtmlAttributes,
//     setBodyAttributes
// }, pluginOptions) => {
//     // setHtmlAttributes(HtmlAttributes)
//     // setHeadComponents(HeadComponents)
//     setBodyAttributes(BodyAttributes)
// }