import React from "react"

export default function LatestNews() {
    return (
        <div className="dt-sc-titled-box-content">
            <div className="pt-cv-wrapper">
                <div className="pt-cv-view pt-cv-scrollable pt-cv-colsys pt-cv-nolf"
                    id="pt-cv-view-88800d7ms5">
                    <div data-id="pt-cv-page-1" className="pt-cv-page" data-cvc="1">
                        <div id="3b1f03dpyu" className="pt-cv-carousel pt-cv-slide"
                            data-ride="cvcarousel" data-interval="false">
                            <div className="carousel-inner">
                                <div className="item active">
                                    <div className="row">
                                        <div
                                            className="col-md-12 pt-cv-content-item pt-cv-1-col">
                                            <div
                                                className="pt-cv-carousel-caption pt-cv-cap-wo-img">
                                                <div className="pt-cv-content">
                                                    <p>Come in store for a chance to win
                                                        Gift Certificate</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};