import { Link } from "gatsby"
import React from "react"
const logoStyle={
    width:'156px',
    height:'73px'
}
const style = {
    "display": "block",
    "overflow": "visible"
}
export default function Header() {
    return (
        <div id="header-wrapper">

            <header id="header" className="header2 ">

                <div className="container">
                    <div id="logo">

                        <a href="/" title="Athena Salon &amp; Medi Spa">
                            <img className="normal_logo" src="/wp-content/uploads/2016/10/athena-logo-withtext.png"
                                alt="Athena Salon &amp; Medi Spa" title="Athena Salon &amp; Medi Spa" />
                            <img className="retina_logo" src="/wp-content/uploads/2016/10/athena-logo-withtext.png"
                                alt="Athena Salon &amp; Medi Spa" title="Athena Salon &amp; Medi Spa"
                                style={logoStyle} />
                        </a>
                    </div>
                    <div id="primary-menu">
                        <nav id="main-menu">
                            <div className="dt-menu-toggle" id="dt-menu-toggle">
                                Menu <span className="dt-menu-toggle-icon"></span>
                            </div>
                        
                            <ul id="menu-header-menu" className="menu with-hover-style" style={style}>
                                <li id="menu-item-211"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home  page_item page-item-117 current_page_item menu-item-depth-0 menu-item-simple-parent ">
                                    <a href="/">Home</a></li>
                                <li id="menu-item-212"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent ">
                                        <Link  activeClassName="current-menu-item" to="/hair-beauty">Hair &#038; Beauty</Link>
                                </li>
                                <li id="menu-item-214"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent ">
                                        <Link to="/spa-treatment">Spa, Manicure &#038; Pedicure</Link>
                                    </li>
                                <li id="menu-item-257"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent ">
                                    <Link to="/wedding-packages">Wedding Packages</Link>
                                </li>
                                <li id="menu-item-219"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0 menu-item-simple-parent ">
                                        <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>

            </header>
        </div>
    )
};