import React from "react"
import { Link } from "gatsby"
export default function BbBarWrapper() {
    return (
        <div id="bbar-wrapper" className="type2">
            <div id="bbar-body">
                <div className="container">
                    <div className="column dt-sc-one-half first">
                        <ul className="dt-sc-social-icons ">
                            <li className="facebook">
                                <a href="https://www.facebook.com/AthenaSalonandMedispa/"
                                    rel="noreferrer"
                                    target="_blank">
                                    <img
                                        src="/wp-content/themes/spalab/images/sociable/hover/facebook.png"
                                        alt="facebook.png" />
                                    <img
                                        src="/wp-content/themes/spalab/images/sociable/facebook.png"
                                        alt="facebook.png" />
                                </a>
                            </li>
                            <li className="instagram">
                                <a href="https://www.instagram.com/athenasalonandspa/"
                                    rel="noreferrer"
                                    target="_blank">
                                    <img
                                        src="/wp-content/themes/spalab/images/sociable/hover/instagram.png"
                                        alt="instagram.png" />
                                    <img
                                        src="/wp-content/themes/spalab/images/sociable/instagram.png"
                                        alt="instagram.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="column dt-sc-one-half alignright">
                        <span className="fa fa-phone-square"> </span><a href="tel:+19057943822">+1 905 794 3822</a>
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                </div>
            </div>
            <span className="bbar-divider"></span>
        </div>

    )
};