import React from "react"

export default function Footer() {
    return (
        <footer id="footer">
            <div className="copyright gradient-bg">
                <div className="container">
                    <div className="copyright-content">All of our services are provided by trained and experience staff,
                        to ensure you and your party are accommodated please make appointments as necessary. Please
                        ensure to cancel or reschedule at-least 4-5 hours in advance. Prices are subjected to change
                        without notice. All prices do not include taxes. Copyright &copy; {(new Date().getFullYear())} AthenaMediSpa All
                        Rights Reserved | <a href="http://www.AthenaMediSpa.com/" title=""> Athena</a>
                    </div>
                    <div className="dt-sc-hr-invisible-small  "></div>
                    <div className="footer-menu">
                        <ul id="menu-header-menu-1" className="footer-menu">
                            <li id="menu-item-211"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-117 current_page_item menu-item-211">
                                <a href="/" aria-current="page">Home</a></li>
                            <li id="menu-item-212"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-212"><a
                                    href="/hair-beauty/">Hair &#038; Beauty</a></li>
                            <li id="menu-item-214"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-214"><a
                                    href="/spa-treatment/">Spa, Manicure &#038; Pedicure</a></li>
                            <li id="menu-item-257"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-257"><a
                                    href="/wedding-packages/">Wedding Packages</a></li>
                            <li id="menu-item-219"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-219"><a
                                    href="/contact-us/">Contact Us</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>

    )
};