import React from "react"
import { Link } from "gatsby"
const dashboardStyle = {
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'left top',
    'paddingBottom': '10px'
}
export default function Dashboard() {
    return (
        <div className="fullwidth-section" style={dashboardStyle}>
            <div className="fullwidth-bg">
                <div className="container">
                    <div className="border-title ">
                        <h2>Welcome to Athena</h2>
                        <div className="ico-border"> <i className="ico-bg flower"></i> </div>
                        <span className="tag-line">Experience the God and Goddess difference</span>
                    </div>
                    <div className="column dt-sc-one-fourth  first">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic">
                                <Link to="/hair-beauty/#waxing">
                                    <img src="/wp-content/uploads/2016/10/facial-laday-brush-280px.jpg" alt="" title="" />
                                </Link> </span> </div>
                            <h3>
                                <Link to="/hair-beauty/#waxing">Hair &#038; Waxing</Link>
                            </h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic">
                                <Link to="hair-beauty/#facial">
                                    <img
                                        src="/wp-content/uploads/2016/10/lady-face-smile-300px.jpg" alt="" title="" />
                                </Link>
                            </span> </div>
                            <h3>   <Link to="hair-beauty/#facial"> Facial </Link></h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/hair-beauty/#ladies">
                            <img
                                src="/wp-content/uploads/2016/10/ladies-hair-curly-280px.jpg" alt="" title="" />
                            </Link> </span> </div>
                            <h3><Link to="/hair-beauty/#ladies">Ladies Hair Care
                            </Link></h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/hair-beauty/#mens">
                             <img
                                src="/wp-content/uploads/2016/10/men-hair-cut-catalog.jpg" alt="" title="" /> 
                                </Link>
                            </span> </div>
                            <h3> <Link to="/hair-beauty/#mens"> Mens Hair Care </Link>
                            </h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  first">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/spa-treatment/#body">
                             <img
                                src="/wp-content/uploads/2016/10/Athena-Women-Spa.png" alt="" title="" /> 
                                </Link>
                            </span> </div>
                            <h3><Link to="/spa-treatment/#body">Body Treatment </Link>
                            </h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/spa-treatment/#manicure">
                            <img
                                src="/wp-content/uploads/2016/10/manicure-hand-nail-catalog.jpg" alt="" title="" />
                            </Link></span> </div>
                            <h3><Link to="/spa-treatment/#manicure"> Manicure &amp;
                                Pedicure </Link></h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/hair-beauty/#laser">
                            <img
                                src="/wp-content/uploads/2016/10/lady-seating-catalog.jpg" alt="" title="" /> 
                                </Link>
                            </span> </div>
                            <h3> <Link to="/hair-beauty/#laser"> Laser Hair Removal
                            </Link></h3>
                        </div>
                    </div>
                    <div className="column dt-sc-one-fourth  ">
                        <div className="dt-sc-ico-content  type2">
                            <div className="icon"> <span className="ico-pic"> 
                            <Link to="/wedding-packages">
                             <img
                                src="/wp-content/uploads/2016/10/Wedding-black-white.jpg" alt="" title="" /> </Link>
                            </span> </div>
                            <h3><Link to="/wedding-packages"> Wedding Packages </Link></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};