import React from "react"

export default function HoursOfOpsTable() {
    return (
        <table>
            <tbody>
                <tr>
                    <td>Monday</td>
                    <td>
                        <span className="medium select_end">10:30 am</span><span> - To -
                        </span><span className="medium select_end">7:30 pm</span>
                    </td>
                </tr>
                <tr>
                    <td>Tuesday</td>
                    <td>Closed</td>
                </tr>
                <tr>
                    <td>Wednesday</td>
                    <td>
                        <span className="medium select_end">10:30 am</span><span> - To -
                        </span><span className="medium select_end">7:30 pm</span>
                    </td>
                </tr>
                <tr>
                    <td>Thursday</td>
                    <td>
                        <span className="medium select_end">10:30 am</span><span> - To -
                        </span><span className="medium select_end">7:30 pm</span>
                    </td>
                </tr>
                <tr>
                    <td>Friday</td>
                    <td>
                        <span className="medium select_end">10:30 am</span><span> - To -
                        </span><span className="medium select_end">7:30 pm</span>
                    </td>
                </tr>
                <tr>
                    <td>Saturday</td>
                    <td>
                        <span className="medium select_end">10:30 am</span><span> - To -
                        </span><span className="medium select_end">7:30 pm</span>
                    </td>
                </tr>
                <tr>
                    <td>Sunday</td>
                    <td>
                        <span className="medium select_end">11:00 am</span><span> - To -
                        </span><span className="medium select_end">5:30 pm</span>
                    </td>
                </tr>
            </tbody>
        </table>

    )
};