import * as React from "react"
import Layout from "./layout"
import Dashboard from "../components/dashboard"
import HoursOfOpsAndNewContainer from "../components/hours-of-ops-and-news"
import CustomerFeedBackSummary from "../components/customer-feedback-summary"
import Slider from "../components/slider"
const IndexPage = () => {
    return (
        <Layout>
            <Slider/>
            <Dashboard />
            <HoursOfOpsAndNewContainer />
            <CustomerFeedBackSummary />
        </Layout>
    )
}

export default IndexPage

export const Head = () => (
    <>
        <title>Athena Medi Spa</title>
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
        <link rel="dns-prefetch" href="//code.jquery.com" />
        <link rel="stylesheet" id="mytheme-google-fonts-css"
            href="https://fonts.googleapis.com/css?family=Lato:300,400,700%7CRaleway:400,500,300,600%7CTangerine:400,700%7CRoboto"
            type="text/css" media="all" />
        <link rel="stylesheet" id="ls-google-fonts-css"
            href="https://fonts.googleapis.com/css?family=Lato:100,300,regular,700,900%7COpen+Sans:300%7CIndie+Flower:regular%7COswald:300,regular,700&#038;subset=latin%2Clatin-ext"
            type="text/css" media="all" />
        <link href="wp-content/uploads/2016/10/athena-icon.png" rel="shortcut icon" type="image/x-icon" />
        <link rel="stylesheet" id="jquery-ui-datepicker-css"
            href="https://code.jquery.com/ui/1.11.0/themes/smoothness/jquery-ui.css?ver=6.0.3" type="text/css" media="all" />
    </>
)