import React from "react"
import { Link } from "gatsby"
const customerFeeedBackStyle = {
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'left top',
    'paddingBottom': '10px'
}
const feedBackStyle = {
    'textAlign': 'right'
}
export default function CustomerFeedBackSummary() {
    return (
        <>
            <div className="border-title ">
                <h2>We love our customers</h2>
                <div className="ico-border"> <i className="ico-bg flower"></i> </div>
                <span className="tag-line">Here is what they are saying</span>
            </div>
            <div className="fullwidth-section  " style={customerFeeedBackStyle}>
                <div className="fullwidth-bg">
                    <div className="container">
                        <div className="dt-sc-testimonial-carousel-wrapper ">
                            <ul className="dt-sc-testimonial-carousel">
                                <li>
                                    <div className="dt-sc-testimonial ">
                                        <div className="author"><img
                                            src="https://1.gravatar.com/avatar/1b0bddb2566e47fa8eb9d133f50a1e6b?s=32&amp;d=mm&amp;r=g"
                                            alt="<span>, Via Facebook</span>" title=" Sunil Sharma" />
                                        </div>
                                        <blockquote>"<q>It is unique nice and clean with dedication of
                                            services.</q>"</blockquote>
                                        <cite> - Sunil Sharma<span>, Via Facebook</span></cite>
                                    </div>
                                </li>
                                <li>
                                    <div className="dt-sc-testimonial ">
                                        <div className="author"><img
                                            src="https://1.gravatar.com/avatar/1b0bddb2566e47fa8eb9d133f50a1e6b?s=32&amp;d=mm&amp;r=g"
                                            alt="<span>, Via Facebook</span>" title=" Bindu Saul" />
                                        </div>
                                        <blockquote>"<q>Very nice and clean facility. Friendly and
                                            trained staff.</q>"</blockquote>
                                        <cite> - Bindu Saul<span>, Via Facebook</span></cite>
                                    </div>
                                </li>
                            </ul>
                            {/* <div className="carousel-arrows"> <a className="testimonial-prev"> <span
                                className="fa fa-caret-left"> </span> </a> <a
                                    className="testimonial-next"> <span className="fa fa-caret-right"> </span>
                                </a>
                            </div> */}
                        </div>

                        <div className="dt-sc-clear"></div>
                        <div className="dt-sc-hr-invisible  "></div>
                        <p></p>

                        <h2 className="dark-title hr-title"><span>Wedding Packages</span></h2>
                        <div className="column dt-sc-one-third  first">
                            <h3 className="floral-head alignright"><span>Special Events</span></h3>
                            <div className="dt-sc-hr-invisible-small  "></div>
                            <div className="dt-sc-clear"></div>
                            <p>&nbsp;</p>
                            <p style={feedBackStyle}>Inquire about wedding packages, bridal and
                                group makeup session for every special occasion.</p>
                        </div>

                        <div className="column dt-sc-one-third  ">
                            <img data-attachment-id="416"
                                data-permalink="https://athenamedispa.com/home/wedding-color-catalog/"
                                data-orig-file="https://i0.wp.com/athenamedispa.com/wp-content/uploads/2016/10/wedding-color-catalog.jpg?fit=133%2C200&amp;ssl=1"
                                data-orig-size="133,200" data-comments-opened="1"
                                data-image-meta="{&quot;aperture&quot;:&quot;0&quot;,&quot;credit&quot;:&quot;&quot;,&quot;camera&quot;:&quot;&quot;,&quot;caption&quot;:&quot;&quot;,&quot;created_timestamp&quot;:&quot;0&quot;,&quot;copyright&quot;:&quot;&quot;,&quot;focal_length&quot;:&quot;0&quot;,&quot;iso&quot;:&quot;0&quot;,&quot;shutter_speed&quot;:&quot;0&quot;,&quot;title&quot;:&quot;&quot;,&quot;orientation&quot;:&quot;0&quot;}"
                                data-image-title="wedding-color-catalog" data-image-description=""
                                data-image-caption=""
                                data-medium-file="https://i0.wp.com/athenamedispa.com/wp-content/uploads/2016/10/wedding-color-catalog.jpg?fit=133%2C200&amp;ssl=1"
                                data-large-file="https://i0.wp.com/athenamedispa.com/wp-content/uploads/2016/10/wedding-color-catalog.jpg?fit=133%2C200&amp;ssl=1"
                                className="size-full wp-image-416 aligncenter"
                                src="https://i0.wp.com/athenamedispa.com/wp-content/uploads/2016/10/wedding-color-catalog.jpg?resize=133%2C200&#038;ssl=1"
                                alt="wedding-color-catalog" width="133" height="200"
                                data-recalc-dims="1" />
                            <div className="hr-invisible-very-small"></div>
                            <div className="clear"></div>
                            <p><span className="aligncenter">
                            <Link className="dt-sc-button     medium  " to="/wedding-packages">Contact Us</Link>
                                </span></p>
                        </div>
                        <div className="column dt-sc-one-third  ">
                            <ul className="dt-sc-fancy-list type2">
                                <li>
                                    <i className="fa fa-leaf"></i> Natural products
                                </li>
                                <li>
                                    <i className="fa fa-heart"></i> 100% safe for your skin
                                </li>
                                <li>
                                    <i className="fa fa-shield"></i> Quality product
                                </li>
                                <li>
                                    <i className="fa fa-user-md"></i> Experienced Medi Spa technicians
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
};