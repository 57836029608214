import React from "react"
import HoursOfOpsTable from "./houres-of-op-table"
import LatestNews from "./lastest-news"

export default function HoursOfOpsAndNewContainer() {
    return (
        <>
            <div className="column dt-sc-two-third  first">
                <div className="dt-sc-titled-box  ">
                    <h6 className="dt-sc-titled-box-title"> Hours of Operations</h6>
                    <div className="dt-sc-titled-box-content">
                        <HoursOfOpsTable/>
                    </div>
                </div>
            </div>
            <div className="column dt-sc-one-third  ">
                <div className="dt-sc-titled-box  ">
                    <h6 className="dt-sc-titled-box-title"> Latest News</h6>
                    <LatestNews/>
                </div>
            </div>
            <div className="dt-sc-clear"></div>
            <div className="dt-sc-hr-invisible-medium  "></div>
        </>
    )
};