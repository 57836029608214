import React from "react"
import { Script,withPrefix } from "gatsby"
const ls1 = {
    height: '500px',
}
const ls2 = {
    width: '100%',
    height: '500px',
    margin: '0 auto',
    'marginBottom': '0px'
}

const ls3 = {
    'top': '-24px', left: '107px', background: '#e62e2e', 'whiteSpace': 'nowrap'
}

const ls4 = {
    top: '50px',
    left: '155px',
    fontWeight: '300',
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontfamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '30px',
    lineHeight: '37px',
    color: '#ffffff',
    background: '#A63A13',
    borderRadius: '3px',
    whiteSpace: 'nowrap'
}
const ls5 = {
    top: '105px',
    left: '148px',
    fontFamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '31px',
    color: '#ffffff',
    whiteSpace: 'nowrap'
}

const ls6 = {
    top: '460px',
    left: '610px',
    whiteSpace: 'nowrap'
}

const ls7 = {
    top: '460px',
    left: '650px',
    whiteSpace: 'nowrap'
}

const ls8 = {
    top: '-5px',
    left: '168px',
    whiteSpace: 'nowrap'
}

const ls9 = {
    top: '48px',
    left: '779px',
    fontWeight: '300',
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontFamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '30px',
    lineHeight: '37px',
    color: '#ffffff',
    background: '#C8DEDC',
    borderRadius: '3px',
    whiteSpace: 'nowrap'
}

const ls10 = {
    top: '430px',
    left: '210px',
    whiteSpace: 'nowrap'
}

const ls11 = {
    top: '-9px',
    left: '201px',
    whiteSpace: 'nowrap'
}

const ls12 = {
    top: '23px',
    left: '749px',
    fontWeight: 300,
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontFamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '30px',
    lineHeight: '37px',
    color: '#ffffff',
    background: '#957E5C',
    borderRadius: '3px',
    whiteSpace: 'nowrap'
}

const ls13 = {
    top: '432px',
    left: '1041px',
    whiteSpace: 'nowrap'
}

const ls14 = {
    top: '432px',
    left: '1067px',
    whiteSpace: 'nowrap'
}

const ls15 = {
    top: '-94px',
    left: '135px',
    whiteSpace: 'nowrap'
}

const ls16 = {
    top: '16px',
    left: '983px',
    fontWeight: 300,
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontFamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '30px',
    lineHeight: '37px',
    color: '#ffffff',
    background: '#59524C',
    borderRadius: '3px',
    whiteSpace: 'nowrap'
}

const ls17 = {
    top: '432px',
    left: '1041px',
    whiteSpace: 'nowrap'
}

const ls18 = {
    'top': '-32px',
    left: '103px',
    whiteSpace: 'nowrap'
}

const ls19 = {
    top: '166px',
    left: '275px',
    fontWeight: 300,
    height: '40px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontFamily: "Lato, 'Open Sans', sans-serif",
    fontSize: '30px',
    lineHeight: '37px',
    color: '#ffffff',
    background: '#59524C',
    borderRadius: '3px',
    whiteSpace: 'nowrap'

}

const ls20 = {
    top: '432px',
    left: '1041px',
    whiteSpace: 'nowrap'
}

const ls21 = {
    top: '432px',
    left: '1067px',
    whiteSpace: 'nowrap'
}

const ls22 = {
    top: '430px',
    left: '250px',
    whiteSpace: 'nowrap'
}

const ls23 = {
    top: '432px',
    left: '1067px',
    whiteSpace: 'nowrap'
}
export default function Slider() {
    return (
        <>
            <Script src={withPrefix('/wp-content/plugins/LayerSlider/static/js/greensock.js')} type="text/javascript" />
            <Script src={withPrefix('/wp-content/plugins/LayerSlider/static/js/layerslider.kreaturamedia.jquery.js')} type="text/javascript" />
            <Script src={withPrefix('/wp-content/plugins/LayerSlider/static/js/layerslider.transitions.js')} type="text/javascript" />
            <Script src={withPrefix('/js/layer-slider-init.js')} type="text/javascript" />
            
            <div className="ls-wp-fullwidth-container" style={ls1}>
                <div className="ls-wp-fullwidth-helper">
                    <div id="layerslider_4" className="ls-wp-container"
                        style={ls2}>
                        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;">
                            <img className="ls-l" style={ls3}
                                data-ls="offsetxin:0;delayin:1720;easingin:easeInOutQuart;scalexin:0.7;scaleyin:0.7;offsetxout:-800;durationout:1000;"
                                src="/wp-content/uploads/2016/10/red-hair-lady-with-ring-1.jpg" alt="" />
                            <p className="ls-l"
                                style={ls4}
                                data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;">
                                Hair Styling</p>
                            <p className="ls-l"
                                style={ls5}
                                data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:-400;">
                                Eyebrow Tinting</p>
                            <img className="ls-l ls-linkto-3" style={ls6}
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/left.png" alt="" /><img
                                className="ls-l ls-linkto-2" style={ls7}
                                data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/right.png" alt="" />
                        </div>
                        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;">
                            <img
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/fw-1.jpg" className="ls-bg"
                                alt="fw-1" /><img className="ls-l" style={ls8}
                                    data-ls="offsetxin:300;durationin:2000;offsetxout:-300;parallaxlevel:-1;"
                                    src="/wp-content/uploads/2016/10/Athena-Women-Refresh-1024.jpg" alt="" />
                            <p className="ls-l"
                                style={ls9}
                                data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;parallaxlevel:10;">
                                Experience refreshing facial </p>
                            <img className="ls-l ls-linkto-1" style={ls10}
                                data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;parallaxlevel:3;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/left.png" alt="" /><img
                                className="ls-l ls-linkto-3" style={ls22}
                                data-ls="offsetxin:50;delayin:1000;offsetxout:50;parallaxlevel:3;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/right.png" alt="" />
                        </div>
                        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;">
                            <img className="ls-l" style={ls11}
                                data-ls="offsetxin:-200;durationin:2000;offsetxout:-200;"
                                src="/wp-content/uploads/2016/10/Athena-Women-Spa.png" alt="" />
                            <p className="ls-l"
                                style={ls12}
                                data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-400;durationout:1000;">
                                Spa &amp; Massage</p>
                            <img className="ls-l ls-linkto-2" style={ls13}
                                data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/left.png" alt="" /><img
                                className="ls-l ls-linkto-1" style={ls14}
                                data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/right.png" alt="" />
                        </div>
                        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;">
                            <img className="ls-l" style={ls15}
                                data-ls="offsetxin:-200;durationin:2000;offsetxout:-200;"
                                src="/wp-content/uploads/2016/10/manicure-pedicure-1024.jpg" alt="" />
                            <p className="ls-l"
                                style={ls16}
                                data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-400;durationout:1000;">
                                Manicure &amp; Pedicure</p>
                            <img className="ls-l ls-linkto-2" style={ls17}
                                data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/left.png" alt="" /><img
                                className="ls-l ls-linkto-1" style={ls23}
                                data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/right.png" alt="" />
                        </div>
                        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;">
                            <img className="ls-l" style={ls18}
                                data-ls="offsetxin:-200;durationin:2000;offsetxout:-200;"
                                src="/wp-content/uploads/2016/10/Wedding-black-white.jpg" alt="" />
                            <p className="ls-l"
                                style={ls19}
                                data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-400;durationout:1000;">
                                Wedding Packages</p>
                            <img className="ls-l ls-linkto-2" style={ls20}
                                data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/left.png" alt="" /><img
                                className="ls-l ls-linkto-1" style={ls21}
                                data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
                                src="/wp-content/uploads/layerslider/Full-width-demo-slider/right.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};